import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import RichText from '../../components/richText';

export const Team = ({ slice }) => {

  return (

    <div className="o-wrapper o-wrapper--xl">

      {/* TEAM */}
      <div className="c-about__team c-team o-section">
        <div className="c-team__heading c-heading c-heading--center o-section--b">
          <h3 className="c-heading__kicker">{slice.primary.team_kicker}</h3>
          {slice.primary.team_title &&
            <div className="c-heading__heading">
              <RichText render={slice.primary.team_title.richText} />
            </div>
          }
          {slice.primary.team_description &&
            <div className="c-heading__description u-text">
              <RichText render={slice.primary.team_description.richText} />
            </div>
          }
        </div> {/* .c-team__heading */}

        <div className="c-team__imgs">
          {slice.primary.team_first_image && slice.primary.team_first_image.url &&
            <div className="c-team__col c-team__col--1">
              <GatsbyImage className={`c-team__pic c-team__pic--1 c-pic c-pic--cover c-pic--bg`} image={slice.primary.team_first_image.gatsbyImageData} />
            </div>
          }
          <div className="c-team__col c-team__col--2">
          {slice.primary.team_second_image && slice.primary.team_second_image.url &&
              <GatsbyImage className={`c-team__pic c-team__pic--2 c-pic c-pic--cover c-pic--bg`} image={slice.primary.team_second_image.gatsbyImageData} />
          }
          {slice.primary.team_third_image && slice.primary.team_third_image.url &&
              <GatsbyImage className={`c-team__pic c-team__pic--3 c-pic c-pic--cover c-pic--bg`} image={slice.primary.team_third_image.gatsbyImageData} />
          }

          </div> {/* .c-team__col */}
        </div> {/* .c-team__imgs */}


      </div> {/* .c-team */}

    </div>

  )

}

export const query = graphql`
  fragment PostDataBodyTeam on PrismicAboutDataBodyTeam {
    primary {
      team_description {
        richText
      }
      team_kicker
      team_title {
        richText
      }
      team_first_image{
        gatsbyImageData(
          
          breakpoints: [414,767,768,980,1024,1440,1680,1920]
        )
        url
      }
      team_second_image{
        gatsbyImageData(
          
          breakpoints: [414,767,768,980,1024,1440,1680,1920]
        )
        url
      }
      team_third_image{
        gatsbyImageData(
          
          breakpoints: [414,767,768,980,1024,1440,1680,1920]
        )
        url
      }
    }
  }
`