import * as React from 'react'
import { graphql } from 'gatsby'
import RichText from '../../components/richText';
import { GatsbyImage } from "gatsby-plugin-image"


export const Approach = ({ slice }) => {

  return (

    <div className="o-wrapper o-wrapper--xl">

      {/* APPROACH */}

      <div className="c-about__approach c-approach">

        {slice.items.length > 0 &&
          <div className="c-approach__imgs">

            {slice.items.map((item, i) => (
              item.approach_image && item.approach_image.url &&
                <div className={`c-approach__pic c-approach__pic--${i + 1} c-pic c-pic--cover c-pic--bg`}>
                  <GatsbyImage key={i} image={item.approach_image.gatsbyImageData} />
                </div>
            ))}

          </div>
        }


        <div className="c-approach__info u-text o-section">
          <div className="c-approach__title c-title">
            {slice.primary.approach_title && <RichText render={slice.primary.approach_title.richText} />}
          </div> {/* .c-approach__title */}
          {slice.primary.approach_description && <RichText render={slice.primary.approach_description.richText} />}
        </div> {/* .c-approach__info */}
      </div> {/* .c-approach */}

    </div>

  )

}


export const query = graphql`
  fragment PostDataBodyApproach on PrismicAboutDataBodyOurApproach {
    primary {
      approach_description {
        richText
      }
      approach_title {
        richText
      }
    }
    items {
      approach_image {
        gatsbyImageData(
          
          breakpoints: [414,767,768,980,1024,1440,1680,1920]
        )
        url
      }
    }
  }
`