import { Hero } from "./hero";
import { Milestones } from "./milestones";
import { Approach } from "./approach";
import { Community } from "./community";
import { Video } from "./video";
import { Team } from "./team";
import { Values } from "./values";

export const components = {
  hero: Hero,
  milestones: Milestones,
  our_approach: Approach,
  community: Community,
  video: Video,
  team: Team,
  values: Values
}