
import React, { useEffect, useState } from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout';
import Seo from '../components/seo';
import Helmet from "react-helmet"
import { withPrefix } from 'gatsby';
import { SliceZone } from '@prismicio/react'
import { components } from '../slices/about/index'
import Openings from "../components/openings";


const About = ({ data }) => {

  const [hasMounted, setHasMounted] = React.useState(false);

  useEffect(() => {
    setHasMounted(true);
    document.querySelector('html').classList.add('smooth');
  }, []);


  //Patch data
  if (!data) return null

  const $metaData = data.prismicMetadata.data;
  const $cta = data.prismicCalltoaction ? data.prismicCalltoaction.data : null;
  const $footer = data.prismicFooter.data;
  const $clutch = data.prismicClutch ? data.prismicClutch.data : null;
  const $socials = data.prismicSocials ? data.prismicSocials.data : null;
  const $contactUs = data.prismicContactUs ? data.prismicContactUs.data : null;

  const $about = data.prismicAbout.data;


  return (

    <Layout cta={$cta} footer={$footer} clutch={$clutch} socials={$socials} contactUs={$contactUs}>

      <Seo metadata={$metaData} title={$about.page_title ? $about.page_title : null} description={$about.page_description ? $about.page_description : null} keywords={$about.page_keywords ? $about.page_keywords : null}></Seo>

      <Helmet>

        {/* No inline CSS */}
        <link href={withPrefix('../../assets/css/sitio/about.approach.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/about.team.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/about.values.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/about.video.css')} rel="stylesheet" type="text/css" />

        {hasMounted && <script src="../../assets/js/sitio/about.js" />}
        {hasMounted && <script src="../../assets/js/sitio/openings.js" />}

      </Helmet>


      <main>
        <section className="c-about" aria-label={$about.page_title ? $about.page_title : null}>

          <SliceZone slices={$about.body} components={components} />

        </section> {/* .c-about */}

      </main>


    </Layout >


  )
}

export const query = graphql`
  query AboutQuery {
    prismicAbout {
      data {
        page_title
        page_description
        page_keywords
        body {
          ... on PrismicAboutDataBodyCommunity {
            slice_type
          }
          ...PostDataBodyCommunity
          ... on PrismicAboutDataBodyHero {
            slice_type
          }
          ...PostDataBodyHero
          ... on PrismicAboutDataBodyMilestones {
            slice_type
          }
          ...PostDataBodyMilestones
          ... on PrismicAboutDataBodyOurApproach {
            slice_type
          }
          ...PostDataBodyApproach
          ... on PrismicAboutDataBodyTeam {
            slice_type
          }
          ...PostDataBodyTeam
          ... on PrismicAboutDataBodyValues {
            slice_type
          }
          ...PostDataBodyValues
          ... on PrismicAboutDataBodyVideo {
            slice_type
          }
          ...PostDataBodyVideo
        }
      }
      uid
    },
    prismicMetadata {
      ...MetadataFragment
    },
    prismicClutch {
          ...ClutchFragment
        },
    prismicFooter{
          ...FooterFragment
        }
    prismicSocials{
      ...SocialsFragment   
    }
    prismicContactUs{
      ...ContactUsFragment   
    }
    prismicCalltoaction{
      ...CallToActionFragment
    }

}
`

export default About
