import * as React from 'react'
import { graphql } from 'gatsby'
import RichText from '../../components/richText';
import Openings from '../../components/openings';


export const Hero = ({ slice }) => {

  return (

    <>

      <div className="c-about__hero c-hero c-about-hero c-hero o-wrapper">
        <div className="c-about-hero__heading c-heading">
          <h2 className="c-heading__kicker">{slice.primary.about_kicker}</h2>
          {slice.primary.about_title &&
            <div className="c-heading__heading">
              <RichText render={slice.primary.about_title.richText} />
            </div>
          }
          {slice.primary.about_description &&
            <div className="c-heading__description u-text">
              <RichText render={slice.primary.about_description.richText} />
            </div>
          }
        </div> {/* .c-about-hero__heading */}

        <Openings />
        
      </div>

     

    </>
  )

}


export const query = graphql`
  fragment PostDataBodyHero on PrismicAboutDataBodyHero {
    primary {
      about_kicker
      about_title {
        richText
      }
      about_description {
        richText
      }
    }
  }
`