import * as React from 'react'
import { graphql } from 'gatsby'
import RichText from '../../components/richText';

export const Milestones = ({ slice }) => {

  return (

    slice.items.length > 0 &&
    <div className="c-about__milestones c-milestones o-section o-wrapper">
      <h3 className="u-hidden-for-seo">{slice.primary.milestones_title}</h3>
      <ul className="c-milestones__list">
        {slice.items.map((item, i) => {

          if (item.milestone_value.text) {

            let splitedValue = (item.milestone_value.text).split('/')

            return (
              <li key={i} className={`c-milestones__item c-milestones__item--${i + 1}`}>


                <h4>

                  {splitedValue.length > 2 ?

                    isNaN(splitedValue[0]) ?
                      <>
                        <em>{splitedValue[0]}</em>
                        <strong>{splitedValue[1]} </strong>
                        <strong>{splitedValue[2]} </strong>
                      </>
                      :
                      <>
                        <strong>{splitedValue[0]}</strong>
                        <em>{splitedValue[1]} </em>
                        <strong>{splitedValue[2]} </strong>
                      </>

                    :
                    <>
                      <strong>{splitedValue[0]} </strong>
                      {splitedValue[1] ? <strong>{splitedValue[1]} </strong> : '' }
                    </>
                  }


                </h4>



                {item.milestone_description &&
                  <div className="c-milestones__text">
                    <RichText render={item.milestone_description.richText} />
                  </div>
                }
              </li>
            )
          }
        })}
      </ul> {/* .c-milestones__list */}
    </div>
  )

}


export const query = graphql`
  fragment PostDataBodyMilestones on PrismicAboutDataBodyMilestones {
    primary{
      milestones_title
    }
    items {
      milestone_description {
        richText
      }
      milestone_value {
        text
        richText
      }
    }
  }
`