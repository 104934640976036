import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import RichText from '../../components/richText';

export const Community = ({ slice }) => {

  return (

    <div className="o-wrapper o-wrapper--xl">

      {/* COMMUNITY */}
      <div className="c-about__community c-community">

        {slice.primary.community_image && slice.primary.community_image.url &&
          <div className="c-community__pic c-pic c-pic--cover c-pic--bg">
            <GatsbyImage image={slice.primary.community_image.gatsbyImageData} />
          </div>
        }

        <div className="c-community__info u-text o-section">
          {slice.primary.community_title &&
            <div className="c-community__title c-title">
              <RichText render={slice.primary.community_title.richText} />
            </div>
          }
          {slice.primary.community_description &&
            <RichText render={slice.primary.community_description.richText} />
          }
        </div> {/* .c-community__info */}
      </div> {/* .c-community */}
    </div>

  )

}


export const query = graphql`
  fragment PostDataBodyCommunity on PrismicAboutDataBodyCommunity {
    primary {
      community_description {
        richText
      }
      community_image {
        gatsbyImageData(
          
          breakpoints: [414,767,768,980,1024,1440,1680,1920]
        )
        url
      }
      community_title {
        richText
      }
    }
  }
`