import * as React from 'react'
import { graphql } from 'gatsby'
import { Waypoint } from 'react-waypoint';


export const Video = ({ slice }) => {


  return (

    slice.primary.video_link && slice.primary.video_link.url &&
    <div className="o-wrapper o-wrapper--xl o-section--t">

      <Waypoint
        onEnter={({ previousPosition, currentPosition, event }) => {
          const videoHolderId = 'js-video';
          const $videoHolder = document.getElementById(videoHolderId);
          const $video = document.querySelector('.js-video__video');
          
          $videoHolder.classList.add('is-loading')
          $videoHolder.classList.remove('is-paused')
          $video.play()
        }}
        onLeave={({ previousPosition, currentPosition, event }) => {
          const videoHolderId = 'js-video';
          const $videoHolder = document.getElementById(videoHolderId);
          const $video = document.querySelector('.js-video__video');

          $video.pause()
          $videoHolder.classList.remove('is-playing')
          $videoHolder.classList.add('is-paused')
        }}
        bottomOffset="0%">
      </Waypoint>

      <div id="js-video" className="c-about__video c-video is-loading is-muted">
        <video width={1680} height={945} preload="none" muted="true" loop="true" playsInline="playsinline" poster={slice.primary.video_poster ? slice.primary.video_poster.url : null} className="c-video__video js-video__video">
          <source src={slice.primary.video_link.url} type="video/mp4" />
        </video>
        <button type="button" className="c-video__btn c-video__btn--right c-video__sound js-video__sound icon-sound-off" title="Enable Sound" />
        <button type="button" className="c-video__btn c-video__btn--left c-video__play js-video__play icon-play" title="Play video" />
        <button type="button" className="c-video__btn c-video__btn--left c-video__pause js-video__pause icon-pause" title="Pause video" />
      </div> {/* .c-about__video */}

    </div>

  )

}


export const query = graphql`
  fragment PostDataBodyVideo on PrismicAboutDataBodyVideo {
    primary {
      video_link {
        url
      }
      video_poster{
        url
      }
    }
  }
`