import * as React from 'react'
import { graphql } from 'gatsby'
import RichText from '../../components/richText';
import ImgResolver from '../../utils/imgResolver'

export const Values = ({ slice }) => {

  return (

    slice.items.length > 0 &&
    <div className="c-about__values c-values o-section" >
      <div className="c-values__wrapper o-wrapper o-wrapper--xl">

        {slice.primary.values_title && <div className="c-values__heading c-heading__heading">
          <RichText render={slice.primary.values_title.richText} />
        </div>}

        <ul className="c-values__list">
          {slice.items.map((item, i) => {

            return (
            <li key={i} className="c-values__item">
              {item.value_icon && item.value_icon.url &&
                ImgResolver(item.value_icon, item.value_name, 'c-values__icon')
              }
              <h3 className="c-values__title">{item.value_name}</h3>

              {item.value_description && <div className="c-values__text">
                <RichText render={item.value_description.richText} />
              </div>}

            </li>
            )

          })}
        </ul>

      </div> {/* .c-values__wrapper */}
    </div>

  )

}


export const query = graphql`
  fragment PostDataBodyValues on PrismicAboutDataBodyValues {
    items {
      value_description {
        richText
      }
      value_icon {
        dimensions{
          width
          height
        }
        url
      }
      value_name
    }
    primary {
      values_title {
        richText
      }
    }
  }
`